@font-face {
  font-family: 'AvertaBold';
  src: url("./assets/fonts/AvertaBold.ttf") format('truetype');
}

@font-face {
  font-family: 'AvertaRegular';
  src: url("./assets/fonts/AvertaRegular.ttf") format('truetype');
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1000px !important
  }
}

@media (min-width: 1400px) {
  .modal-dialog {
    max-width: 1200px !important
  }
}

:root {
  font-family: 'AvertaRegular' !important;
}

body {
  font-family: 'AvertaRegular' !important;
  font-variant: normal !important;
  color: #1E2B39;
}

h1, h2, h3, h4, h5 {
  color: #1E2B39 !important;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #1E2B39 !important;
}

body strong {
  font-family: 'AvertaBold' !important;
}

.uzit-title {
  font-family: "AvertaBold";
  font-size: 30px;
  color: #1E2B39;
  text-align: center;
}

.login-title {
  font-family: "AvertaBold";
  font-size: 20px;
  color: #1E2B39;
  text-align: center;
}

.gallery-item {
  position: relative;
  width: 250px;
  height: 200px;
  border: 2px solid rgba(255, 255, 255, 1);
}

.gallery-item.library {
  position: relative;
  width: auto;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 1);
}

.gallery-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery-item-img.library {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: cover;
}

.gallery-item-margin-bottom {
  margin-bottom: 2rem;
}

.ant-select {
  border-radius: 0.375rem !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  .ant-select-selector {
    height: calc(2.75rem + 2px);
    width: 100%;
    border-radius: 0.375rem !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0px !important;
    transition: box-shadow 0.15s ease;
    background-color: #fff;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gallery-item.library img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.dropdown-menu {
  z-index: 9
}

.gallery-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  clear: float;
  width: 100%;
  height: 100%;
  border: 5px solid #DC353C;
  color: #ffffff;
}

.gallery-item-overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.gallery-item-overlay-drop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(255, 255, 255, 0);
}

.gallery-item:hover .gallery-item-overlay-text {
  opacity: 1;
}

.gallery-item:hover .gallery-item-overlay-drop {
  opacity: 1;
}

.gallery-item:hover {
  border: 2px solid #DC353C;
}

.gallery-item.activeImg {
  border: 5px solid #DC353C;
  .gallery-item-img {
    padding: 2px;
  }
}

.gallery-item-overlay-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
  position: absolute;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.gallery-item-overlay-dropdown {
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 91%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;

  .dropdown a {
    background-color: rgba(255, 255, 255, 1);
  }

  button {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 50px;
    width: 43px;
    background-color: lightgray;
  }

  i {
    color: red;
  }
}

.autosave {
  font-size: .75rem;
  letter-spacing: .04em;
  color: #8898aa !important;
  margin-right: 1rem;
}

.bg-default {
  background-color: #DC353C !important;
}

.logincard {
  font-family: "AvertaRegular" !important;
  background-color: #fff !important;
  small {
    font-size: 14px !important;
    color: #C0C0C0 !important;
  }

  .input-group-alternative {
    border: 3px solid #C0C0C0;
    box-shadow: none;

    &.filledintext {
      border: 3px solid #4CAB5B;
    }

    i {
      color: #4CAB5B;
    }

    input {
      color: #4CAB5B;
      font-family: "AvertaBold" !important;
      font-size: 22px;
      &::placeholder {
        color: #C0C0C0;
        font-family: "AvertaRegular" !important;
        font-size: 16px;
      }
    }
  }

  button {
    background-color: #4CAB5B !important;
    color: #1E2B39 !important;
    border-color: #4CAB5B !important;
    font-family: "AvertaBold" !important;
  }
}

.bg-gradient-info {
  // background: #DC353C !important;
  background: url("assets/img/bg-image.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
}

.bg-gradient-danger {
  background: #c74a4a !important;
}

.bg-gradient-black {
  background: #2b2f33 !important;
}

.bg-gradient-primary {
  background: #DC353C !important;
}

.verifiedUserIcon {
  height: 18px;
  width: auto;
  padding-bottom: 2px;
}

.avatar {
  padding: 1px;
  width: 40px;
  height: 40px;
}

h1 {
  font-family: "AvertaBold";
  color: rgba(0,0,0,0.85) !important;
}

body {
  font-family: "AvertaRegular";
}

.info-text-on-list {
  padding: 0 !important;
  h4 {
    color: #ffffff !important;
  }
  span {
    color: #ffffff !important;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100% !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  height: 100% !important;
}

img.img-in-listtable {
  height: 39px;
  width: 39px;
  object-fit: cover;

  &.mainpicture-with-radius {
    border-radius: 50%;
  }
}

img.main-content-img {
  height: 10rem;
  width: 10rem;
  object-fit: cover;

  &.round-img {
    border-radius: 50%;
  }
}

img.img-in-table.without-radius {
  height: 39px;
  width: 39px;
  border-radius: 0px !important;
  object-fit: contain;
}

.card {
  border-radius: 15px;
  border: none;
  .card-header:first-child {
    border-radius: 15px;
  }
  .card-footer:last-child {
    border-radius: 15px;
  }
}

.ant-input-affix-wrapper {
  border-radius: 10px !important;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: 0px !important;
  box-shadow: none;
  left: 0 !important;
  button {
    border: 0px !important;
    box-shadow: none;
  }
}

.login-logo-img {
  width: 100%;
  height: 60px;
  object-fit: contain;
}

.white-text-breadcrumb {
  span.ant-breadcrumb-link, span.ant-breadcrumb-separator {
    color: #fff !important;
  }
}
